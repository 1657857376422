import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ImpressumDatenschutzPage = () => (
    <Layout>
      <SEO title="Impressum &amp; Datenschutz" index="noindex, nofollow" description="" />
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-8 is-offset-2">
              <div className="content">
                <h1 className="title is-3">Impressum</h1>
                <p>
                    1comm GmbH<br />
                    Tönninger Strasse 20<br />
                    25836 Garding<br /><br />
                    FON: 0486 22 0 11 280<br />
                    FAX : 030 609609 7676 2<br /><br />
                    Web: https://www.1comm.eu<br />
                    E-Mail: info@1comm.eu<br /><br />
                    Handelregister:<br />
                    HRB 13018 FL<br />
                    Amtsgericht Flensburg<br /><br />
                    Geschäftsführer: Dirk von Flemming<br /><br />
                    USt-IdNr.: DE239451418<br /><br />
                    Zuständige Aufsichtsbehörde:<br />
                    Bundesnetzagentur für Elektrizität, Gas, Telekommunikation, Post und Eisenbahnen (BNetzA), Reg. Nr 09/351
                </p>
                <h2 className="title is-6">Jugendschutz-/ Datenschutzbeautragter</h2>
                <p>
                    Dirk von Flemming<br />
                    E-Mail: jugendschutz@1comm.eu
                </p>
                <h1 className="title is-3">Datenschutzerklärung</h1>
                <p>Soweit nachstehend keine anderen Angaben gemacht werden, ist die Bereitstellung Ihrer personenbezogenen Daten weder gesetzlich oder vertraglich vorgeschrieben, noch für einen Vertragsabschluss erforderlich. Sie sind zur Bereitstellung der Daten nicht verpflichtet. Eine Nichtbereitstellung hat keine Folgen. Dies gilt nur soweit bei den nachfolgenden Verarbeitungsvorgängen keine anderweitige Angabe gemacht wird.</p>
                <p>"Personenbezogene Daten" sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen.</p>
                <h2 className="title is-6">Server-Logfiles</h2>
                <p>Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer Person zu machen. Es werden bei jedem Zugriff auf unsere Website Nutzungsdaten durch Ihren Internetbrowser übermittelt und in Protokolldaten (Server-Logfiles) gespeichert. Zu diesen gespeicherten Daten gehören z.B. Name der aufgerufenen Seite, Datum und Uhrzeit des Abrufs, übertragene Datenmenge und der anfragende Provider. Diese Daten dienen ausschließlich der Gewährleistung eines störungsfreien Betriebs unserer Website und zur Verbesserung unseres Angebotes. Eine Zuordnung dieser Daten zu einer bestimmten Person ist nicht möglich.</p>
                <h2 className="title is-6">Nutzung Dritter im Rahmen der Auftragsverabeitung</h2>
                <p>Wir verwenden Daten zur Vertragsabwicklung und Abwicklung im Rahmen einer Auftragsverarbeitung. Dazu werden Ihre im Rahmen der Abwicklung erhobenen personenbezogenen Daten an<br /><br />
                next id GmbH, Konrad-Zuse-Platz 5, 53227 Bonn<br />
                IN-telegence GmbH, Oskar-Jäger-Straße 125, 50825 Köln<br /><br />
                übermittelt.</p>
                <p>Ihre Daten werden an ein Drittland übermittelt, für welches ein Angemessenheitsbeschluss der Europäischen Kommission vorhanden ist.</p>
                <h2 className="title is-6">Dauer der Speicherung</h2>
                <p>Die Daten werden unter Berücksichtigung gesetzlicher Aufbewahrungsfristen gespeichert und dann nach Fristablauf gelöscht, sofern Sie der weitergehenden Verarbeitung und Nutzung nicht zugestimmt haben.</p>
                <h2 className="title is-6">Rechte der betroffenen Person</h2>
                <p>Ihnen stehen bei Vorliegen der gesetzlichen Voraussetzungen folgende Rechte nach Art. 15 bis 20 DSGVO zu: Recht auf Auskunft, auf Berichtigung, auf Löschung, auf Einschränkung der Verarbeitung, auf Datenübertragbarkeit.</p>
                <p>Außerdem steht Ihnen nach Art. 21 (1) DSGVO ein Widerspruchsrecht gegen die Verarbeitungen zu, die auf Art. 6 (1) f DSGVO beruhen, sowie gegen die Verarbeitung zum Zwecke von Direktwerbung.</p>
                <p>Kontaktieren Sie uns auf Wunsch. Die Kontaktdaten finden Sie in unserem Impressum.</p>
                <h2 className="title is-6">Beschwerderecht bei der Aufsichtsbehörde</h2>
                <p>Sie haben gemäß Art. 77 DSGVO das Recht, sich bei der Aufsichtsbehörde zu beschweren, wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer personenbezogenen Daten nicht rechtmäßig erfolgt.</p>
                <p>Letzte Aktualisierung: 25.04.2018</p>
              </div>
            </div>
          </div>
        </div>
      </section>
  </Layout>
)

export default ImpressumDatenschutzPage